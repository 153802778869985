<template>
  <div>
    <div class="searchbox">
      <div class="searchbox-line">
        <el-input
            placeholder="请输入内容"
            class="searchInput"
            size="small"
            @input="searchCall()"
            v-model="searchContent">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button type="primary" size="small" class="searchButton" @click="searchByKeyWord">搜索</el-button>
      </div>
      <transition name="plus-icon">
        <div class="select-box" v-if="showSelectBox===true && searchContent">
          <template v-if="customerList.length">
            <div class="select-box-item" v-for="(item,index) in customerList" :key="index" @click="chooseIphone(item)">{{item.name}}——{{item.phone}}</div>
          </template>
          <div class="select-box-item" v-else>暂无数据</div>
        </div>
      </transition>
    </div>
    <div class="btnsgroups">
      <div :class="{btnsgroupsbtn:true,active:currentIndex===1}" @click="changeCallingType(1)">呼入</div>
      <div :class="{btnsgroupsbtn:true,active:currentIndex===2}" @click="changeCallingType(2)">呼出</div>
    </div>
    <div class="contentBox">
      <div class="callingItem-box"  v-if="callingRecordsList.length>0">
        <div class="callingItem" v-for="(item,index) in callingRecordsList" :key="index" @click="chooseRecordItem(item,index)">
          <div class="callingDetail" >
            <div class="img-box">
              <img v-if="item.callType==='呼入'" src="../../assets/images/callin-noanswer.png" alt="">
              <img v-if="item.callType==='预览外呼' || item.callType==='直接外呼' || item.callType==='主叫外呼'" src="../../assets/images/callout-noanswer.png" alt="">
            </div>
            <div class="customerInfo">
              <div class="customerName">
                <span>{{item.name}}</span>
                <span class="customerTime">{{timeMode(item.startTime*1000,'/').dateMin}}</span>
              </div>
              <span class="customerTel">{{item.customerNumber}}（{{item.customerProvince}}/{{item.customerCity}}）</span>
              <div style="color: #F56C6C;font-size: 12px;margin-top: 6px">
                <span >坐席响铃未接听</span>
              </div>
            </div>
          </div>
          <div v-if="item.showButton">
            <img src="../../assets/images/callImg.png" alt="" @click.stop="telNumber(item)">
          </div>
        </div>
      </div>
      <div v-else style="color: #606266;text-align: center">暂无通话记录</div>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :total="total">
      </el-pagination>
    </div>
    <!-- 会员列表呼叫、解绑 -->
    <el-dialog
        :visible.sync="callAndUnbundle"
        width="360px"
        :show-close="false"
        :modal="false"
        class="callAndUnbundleBox"
    >
      <div class="callAndUnbundleCon">
        <div class="title">是否呼叫【{{ callName }}】？</div>
        <div class="associatedBtn">
          <div class="associatedCancel" @click="callAndUnbundle=false">
            取消
          </div>
          <div class="associatedSure" @click="callAndUnbundleSure()">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {timeMode} from "../../tools/utils";
export default {
  data(){
    return{
      timeMode,
      dialogRelateUser:false,
      callAndUnbundle:false,
      showSelectBox:false,
      currentIndex:1,
      form:{
        patient:"",
        username:""
      },
      value:"",
      searchContent:"",
      currentPage:1,
      total:10,
      callingRecordsList:[],
      todayTime:{
        startTime:new Date(new Date().setHours(0, 0, 0, 0)).getTime()/1000  ,
        endTime: Math.floor(new Date(new Date().setHours(23,59,59,999)).getTime()/1000)
      },
      callName: "",
      callPhone: "",
      customerList:[],
      customerInfo:""
    }
  },
  created() {
    this.searchCallingRecords()
  },
  methods:{
    chooseRecordItem(item,index){
      item.showButton=!item.showButton
      this.callingRecordsList.forEach((ite,_index)=>{
        if (index!==_index){
          ite.showButton=false
        }
      })
      this.$emit('updateDetail',item,this.currentIndex)

    },
    editRecords(){
      console.log('执行编辑')
      this.dialogRelateUser = true
    },
    handleChange(){
      console.log(4444)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.searchCallingRecords()
    },
    //判断接听
    getAnswerStatus(str){
      if (str==='座席接听' || str==='双方接听'){
        return true
      }else {
        return  false
      }
    },
    //判断未接听
    getUnAnswerStatus(str){
      return str.includes("未接听")
    },
    callAndUnbundleSure() {
      ClinkAgent.previewOutcall({ tel: this.callPhone });
      this.callAndUnbundle = false;
    },
    telNumber(item){
      if(this.$store.state.calling.isLogin){
        console.log(item, "打电话");
        this.callName = item.name;
        this.callPhone = item.customerNumber;
        this.callAndUnbundle = true;
      }else {
        this.$message.warning({
          message:'请先登录呼叫中心!',
          duration:1000
        })
      }
    },
    changeCallingType(index){
      console.log('切换输入输出类容')
      this.currentIndex=index
      this.currentPage=1
      this.searchCallingRecords()
    },
    //获取所有通话记录
    searchCallingRecords(){
      const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
      let params = {
        cno:userInfo.cno,
        keyword:this.customerInfo.phone,
        startTime:'',
        endTime:'',
        status: 2,//0全部 1: 座席接听 2: 已呼叫座席，座席未接听
        pageSize:10,
        page:this.currentPage,
        type:this.currentIndex
      }
      this.$http.getCallingRecords(params).then(res=>{
        console.log(res.result.data,'=============通话记录==============')
        if (res.result.data.callList.length>0){
          res.result.data.callList.forEach(item=>{
            item.showButton=false
          })
          this.callingRecordsList = res.result.data.callList
          this.total =res.result.data.totalCount
          this.$emit('updateDetail',res.result.data.callList[0],this.currentIndex)
        }else {
          this.callingRecordsList = []
        }
      })
    },
    chooseIphone(val){
      this.showSelectBox=false
      this.customerInfo=val
      this.searchCallingRecords()
    },
    searchCall() {
      if (!this.searchContent) {
        this.customerInfo=''
        this.searchCallingRecords()
      }
    },
    searchByKeyWord(){
      let params = {
        keyword:this.searchContent
      }
      this.$http.getCallingPatientsList(params).then(res=>{
        console.log(res,'=======================')
        this.showSelectBox=true
        this.customerList = res.result.data
      }).catch(err=>{
        this.$message.warning(`搜索失败:${err.msg}`)
      })
    }
  },
}
</script>

<style scoped lang="less">
.btnsgroups{
  display: flex;
  cursor: default;
  .btnsgroupsbtn{
    margin-left: 16px;
    width: 76px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DCDEE0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;

  }
  .active{
    background: #EFF7FF;
    border: 1px solid #4FACFF;
    color: #4FACFF;
  }
}
.contentBox{
  padding: 10px 42px;
  max-height: 600px !important;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }
  // 滚动条的轨道（里面装有Thumb）
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  // 滚动条的轨道（里面装有Thumb）
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }
  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }
  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }
  .callingItem{
    font-family: PingFangSC-Regular, PingFang SC !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #C8C9CC;
    box-sizing: border-box;
    .callingDetail{
      display: flex;
      align-items: center;
      .customerInfo{
        margin-left: 15px;
        text-align: left;
        color: #303133;
        .customerName{
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 4px;
        }
        .customerTel{
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
        .customerTime{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-left: 7px;
        }
      }
    }
    .type-box{
      font-size: 12px;
    }

  }
}
.pagination-container{
  .el-pagination{
    margin-top: 16px;
  }
}

// 会员列表呼叫、解绑
/deep/.callAndUnbundleBox {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
    .callAndUnbundleCon {
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        text-align: center  ;
      }
      .associatedBtn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .associatedCancel {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .associatedSure {
          width: 120px;
          height: 40px;
          background: #4facff;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
}
.searchbox{
  display: flex;
  flex-direction: column;
  position: relative;
  .searchbox-line{
    width: 100%;
    display: flex;
  }
  .select-box{
    overflow: auto;
    width: 95%;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    max-height: 200px;
    position: absolute;
    top: 45px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    .select-box-item{
      margin-bottom: 10px;
      font-size: 14px;
      cursor: default;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
    &::-webkit-scrollbar-button {
      display: none;
    }
    // 滚动条的轨道（里面装有Thumb）
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    // 滚动条的轨道（里面装有Thumb）
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
    &::-webkit-scrollbar-thumb {
      background: rgba(144, 147, 153, 0.3);
      cursor: pointer;
      border-radius: 4px;
    }
    // 边角，即两个滚动条的交汇处
    &::-webkit-scrollbar-corner {
      display: none;
    }
    // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
    &::-webkit-resizer {
      display: none;
    }
  }
}
.plus-icon-enter-active{
  transition: opacity .2s;
}
.plus-icon-enter{
  opacity: 0;
}
.plus-icon-leave-active{
  transition: opacity .2s;
}
.plus-icon-leave-to{
  opacity: 0;
}
</style>
