<template>
  <div class="container">
    <div class="box bookBox">
      <ul class="tabBox">
        <li v-for="(item,index) in tabLable"
            :key="index"
            @click="handleToggle(item,index)"
            :class="{active:active===index,tableitem:true}">
          {{item.label}}
        </li>
      </ul>
      <div class="tabsContent">
        <component :is='currentView'  @updateDetail="updateDetail" ></component>
      </div>
    </div>
    <div class="box detailBox" >
      <el-descriptions title="通话详情" :colon="false" style="margin-bottom: 56px" v-if="callingDetail.clientNumber">
        <el-descriptions-item label="用户电话">{{callingDetail.customerNumber}}</el-descriptions-item>
        <el-descriptions-item label="热线/外显号码">{{callingDetail.hotline}}</el-descriptions-item>
        <el-descriptions-item label="呼叫类型">{{callingDetail.callType}}</el-descriptions-item>
        <el-descriptions-item label="接听状态">{{callingDetail.status}}</el-descriptions-item>
        <el-descriptions-item label="通话时长">{{getSeconds(callingDetail.totalDuration)}}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{timeMode((callingDetail.startTime)*1000,'/').dateMin}}</el-descriptions-item>
        <el-descriptions-item label="接通时间">{{callingDetail.bridgeTime? timeMode((callingDetail.bridgeTime)*1000,'/').dateMin : '--'}}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{timeMode((callingDetail.endTime)*1000,'/').dateMin}}</el-descriptions-item>
        <el-descriptions-item label="队列号">{{callingDetail.qno ? callingDetail.qno : '--'}}</el-descriptions-item>
        <el-descriptions-item label="座席工号">{{callingDetail.cno}}</el-descriptions-item>
        <el-descriptions-item label="座席电话">{{callingDetail.clientNumber}}</el-descriptions-item>
      </el-descriptions>
      <div v-else style="text-align: center">暂无通话记录详情</div>
      <div v-for="(info,index) in agents" :key="index">
        <el-divider v-if="(index!==agents.length) && index!==0"></el-divider>
        <el-descriptions :title="index===0? '呼叫座席' : ''" :colon="false" >
          <el-descriptions-item label="坐席姓名">{{info.clientName}}</el-descriptions-item>
          <el-descriptions-item label="座席工号">{{info.cno}}</el-descriptions-item>
          <el-descriptions-item label="坐席电话">{{callingDetail.clientNumber}}</el-descriptions-item>
          <el-descriptions-item label="呼叫类型">{{info.callType}}</el-descriptions-item>
          <el-descriptions-item label="呼叫结果">{{info.sipCause}}</el-descriptions-item>
          <el-descriptions-item label="呼叫情况">{{info.status}}</el-descriptions-item>
          <el-descriptions-item label="主叫记忆">{{info.remember}}</el-descriptions-item>
          <el-descriptions-item label="开始时间">{{timeMode((info.startTime)*1000,'/').dateMin}}</el-descriptions-item>
          <el-descriptions-item label="接通时间">{{info.answerTime? timeMode((info.answerTime)*1000,'/').dateMin : '--'}}</el-descriptions-item>
          <el-descriptions-item label="通话时长">{{getSeconds(info.totalDuration)}}</el-descriptions-item>
        </el-descriptions>
      </div>


      <!--    <div class="callNotesBox">-->
      <!--      <span>未接来电备注</span>-->
      <!--      <div class="notesContent">-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </div>
</template>

<script>
import TodayCalling from "../../components/callingRecords/TodayCalling";
import UnanswerCalling from "../../components/callingRecords/UnanswerCalling";
import HistoryCalling from "../../components/callingRecords/HistoryCalling";
import {timeMode} from "../../tools/utils";
export default {
  name: "CallingRecords",
  components: {
    TodayCalling,
    UnanswerCalling,
    HistoryCalling
  },
  data(){
    return{
      timeMode,
      dialogRelateUser:false,
      textarea1:"",
      currentView:'TodayCalling',
      active: 0,
      historyIndex:0,
      tabLable: [
        {
          label: '今日通话',
          component: 'TodayCalling'
        },
        {
          label: '未接来电',
          component: 'UnanswerCalling'
        },
        {
          label: '历史记录',
          component: 'HistoryCalling'
        },
      ],
      searchContent:"",
      currentPage:1,
      total:10,
      dateRange:[],
      callingRecordsList: [],
      agents:[],
      callingDetail:{}
    }
  },
  created() {
  },
  methods:{
    handleToggle(item,index){
      this.active = index
      this.currentView = item.component
    },
    chooseDetail(desc,index){
      this.historyIndex = index
    },
    //更新通话记录详情
    updateDetail(val,index){
      console.log(val,'================================')
      console.log(index,'================================')
      if (val){
        this.getCallingDetail(val.uniqueId,index)
      }else {
        this.agents=[],
        this.callingDetail={}
      }
    },
    //获取通话记录详情
    getCallingDetail(mainUniqueId,type){
      this.$http.getCallingDetail({mainUniqueId,type}).then(res=>{
        console.log(res.result.data,'=============通话记录详情==============')
        this.callingDetail = res.result.data.Info
        this.agents = res.result.data.agent
      })
    },
    //秒数转化
    getSeconds(s) {
      var sTime = parseInt(s);// 秒
      var mTime = 0;// 分
      var hTime = 0;// 时
      if(sTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        mTime = parseInt(sTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        sTime = parseInt(sTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if(mTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hTime = parseInt(mTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          mTime = parseInt(mTime % 60);
        }
      }
      var result = '';
      if(sTime >=0 && sTime < 10){
        result = "0" + parseInt(sTime) + "";
      }else{
        result = "" + parseInt(sTime) + "";
      }
      if(mTime >= 0 && mTime < 10) {
        result = "0" + parseInt(mTime) + ":" + result;
      }else{
        result = "" + parseInt(mTime) + ":" + result;
      }
      if(hTime >= 0 && hTime < 10) {
        result = "0" + parseInt(hTime) + ":" + result;
      }else{
        result = "" + parseInt(hTime) + ":" + result;
      }
      return result;
    }

  }
}
</script>

<style scoped lang="less">
.container{
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 98%;
  margin-top: 10px;
  .box{
    background: #FFFFFF;
  }
  .bookBox{
    width: 28%;
    position: relative;
    overflow: auto;
    .tabBox{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #323233;
      height: 52px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #D8D8D8;
      padding: 0;
      margin: 0;
      .tableitem{
        padding: 15px 0;
        cursor: pointer;
        list-style: none;
      }
      .active{
        border-bottom: 4px solid #4FACFF;
      }
    }
    /deep/.searchbox{
      margin-top: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchButton{
        margin-left: 10px;
        width: 104px;
      }
    }
  }
  .historyBox{
    width: 22%;
    padding: 16px;
    box-sizing: border-box;
    .title{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #323233;
      text-align: left;
      border-left: 2px solid #4FACFF;
      span{
        margin-left: 10px;
      }
    }

    .selectDateBox{
      margin-top: 10px;
      position: relative;
      width: fit-content;
      display: flex;
      justify-content: center;
      width: 100%;

      /deep/ .el-range__icon {
        display: none;
      }
      .data_icon {
        position: absolute;
        top: 50%;
        right: 17px;
        z-index: 9;
        color: #c0c4cc;
        font-size: 14px;
        transform: translateY(-50%);
      }
      /deep/.el-range__close-icon {
        position: absolute;
        right: 34px;
        top: 54%;
        transform: translateY(-50%);
      }
      .el-input__inner{
        width: 100%;
      }

    }
    .timeLine{
      margin-top: 20px;
      height: 85%;
      overflow-y: scroll;
      box-sizing: border-box;
      padding-top: 28px;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
      &::-webkit-scrollbar-button {
        display: none;
      }
      // 滚动条的轨道（里面装有Thumb）
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      // 滚动条的轨道（里面装有Thumb）
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
      &::-webkit-scrollbar-thumb {
        background: rgba(144, 147, 153, 0.3);
        cursor: pointer;
        border-radius: 4px;
      }
      // 边角，即两个滚动条的交汇处
      &::-webkit-scrollbar-corner {
        display: none;
      }
      // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
      &::-webkit-resizer {
        display: none;
      }
      .m-timeline-area {
        margin: 0 auto;
        .m-timeline {
          .m-timeline-item {
            position: relative;
            padding-bottom: 30px;
            .u-tail {
              position: absolute;
              top: 10px;
              left: 5px;
              height: calc(100% - 10px);
              border-left: 2px solid #e8e8e8; // 实线
              // border-left: 2px dotted #e8e8e8; // 点线
              // border-left: 2px dashed #e8e8e8; // 虚线
            }
            .udot {
              position: absolute;
              width: 8px;
              height: 8px;
              border: 2px solid  #1890ff  ;
              border-radius: 50%;
            }
            .activeDot{
              background-color: #1890ff;
            }
            .activeBorder{
              border: 1px solid  #1890ff!important;
            }
            .ucontent {
              position: relative;
              top: -28px;
              margin-left: 25px;
              word-break: break-all;
              word-wrap: break-word;
              line-height: 24px;
              border: 1px solid #bbbbbb;
              width: 90%;
              height: 71px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid rgba(151,151,151,0.18);
              font-family: PingFangSC-Regular, PingFang SC !important;
              text-align: left;
              box-sizing: border-box;
              padding: 10px 24px;
              .callingType{
                span{
                  font-size: 14px;
                  font-weight: bold;
                  color: #303133;
                }
              }
              .customerTel{
                font-size: 12px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
          .last {
            .u-tail {
              display: none;
            }
          }
        }
      }
    }
    .loadBox{
      font-size: 16px;
      color: #4FACFF;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
  .detailBox{
    width: 70%;
    padding: 32px;
    overflow-y: auto;
    box-sizing: border-box;
    height: calc(100vh - 84px);
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
    &::-webkit-scrollbar-button {
      display: none;
    }
    // 滚动条的轨道（里面装有Thumb）
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    // 滚动条的轨道（里面装有Thumb）
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
    &::-webkit-scrollbar-thumb {
      background: rgba(144, 147, 153, 0.3);
      cursor: pointer;
      border-radius: 4px;
    }
    // 边角，即两个滚动条的交汇处
    &::-webkit-scrollbar-corner {
      display: none;
    }
    // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
    &::-webkit-resizer {
      display: none;
    }
    /deep/.el-descriptions{
      .el-descriptions__header{
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #323233;
        margin-bottom: 20px;
      }
      .el-descriptions__body{
        .el-descriptions__table{
          .el-descriptions-row{
            display: table-row;
          }
          .el-descriptions-item__cell{
            line-height: 35px;
          }
          .el-descriptions-row{
            .el-descriptions-item__label{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #909399;
            }
            .el-descriptions-item__content{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
            }
          }
        }
      }
    }
    .callNotesBox{
      text-align: left;
      margin-top: 50px;
      font-size: 16px;
      font-weight: 700;
      .notesContent{
        width: 100%;
        height: 130px;
        background-color: #42b983;
      }
    }
  }
}
</style>
